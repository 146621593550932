import {
  basementAccessConnectionOptions,
  basementAccessOptions,
  basementOptions,
  buildingTypeOptions,
  buildingYearRangeOptions,
  GebaeudedatenType,
  miscellaneousOptions,
  personsOptions,
  residentialUnitsOptions,
  typeOfBuildingOptions,
  ventilationSystemOptions,
  windowTightnessOptions,
  yesNoUnknownOptions,
} from '../types';
import { Col, Form, Row } from 'react-bootstrap';
import * as React from 'react';
import formFactory from '../../shared/formFactory';
import { FormikErrors, FormikTouched } from 'formik';
import { capFirstLetter } from '../../../../../util/string-util';

type Props = {
  handleChange: (e: React.ChangeEvent<unknown>) => void;
  values: GebaeudedatenType;
  touched: FormikTouched<GebaeudedatenType> | undefined;
  errors: FormikErrors<GebaeudedatenType> | undefined;
  prefix: string;
};

const GebaeudeinformationenForm = ({
  handleChange: handleChangeFormik,
  values,
  touched,
  errors,
  prefix,
}: Props): JSX.Element => {
  const handleChange = (e: React.ChangeEvent<unknown>): void => {
    if ('id' in e.target && e.target.id === 'inline-checkbox-building-buildingYearUnknown') {
      values.buildingYearRange = '';
      values.buildingYear = '';
    }

    handleChangeFormik(e);
  };

  const { formSelect, formText, formCheck } = formFactory(handleChange, values, touched, errors, true, prefix);
  const { selectRow } = formFactory(handleChange, values, touched, errors, true, prefix);

  return (
    <>
      {selectRow('typeOfBuilding', 'Art des Hauses', 'Art des Hauses*', typeOfBuildingOptions)}
      <Row>
        <Col md={'7'}>
          <Form.Label>Baujahr* (Falls unbekannt, bitte Schätzung angeben)</Form.Label>
        </Col>
      </Row>
      <Row className={'mb-3'}>
        <Form.Group as={Col} md={'2'} controlId={`validationFormik${capFirstLetter(prefix)}Year`}>
          {formText('buildingYear', 'Baujahr', true, values.buildingYearUnknown !== false)}
        </Form.Group>
        <Form.Group as={Col} md={'2'} controlId={`validationFormik${capFirstLetter(prefix)}BuildingYearUnknown`}>
          {formCheck('buildingYearUnknown', 'Unbekannt', false)}
        </Form.Group>
        <Form.Group as={Col} md={'3'} controlId={`validationFormik${capFirstLetter(prefix)}BuildingYearRange`}>
          {formSelect('buildingYearRange', 'Baujahr', buildingYearRangeOptions, values.buildingYearUnknown === false)}
        </Form.Group>
      </Row>
      {selectRow('buildingType', 'Bauweise', 'Bauweise', buildingTypeOptions)}
      {selectRow('insulation', 'Wärmedämmung', 'Wärmedämmung (oberirdische Hausaußenwände)', yesNoUnknownOptions)}
      {selectRow(
        'insulationWindows',
        'Isolierverglasung Fenster',
        'Isolierverglasung Fenster (oberirdisch)',
        yesNoUnknownOptions
      )}
      {selectRow('residentialUnits', 'Wohneinheiten', 'Zahl der Wohneinheiten im Gebäude*', residentialUnitsOptions)}
      {selectRow('persons', 'Personen im Haushalt', 'Personen im Haushalt*', personsOptions)}
      {selectRow('basement', 'Unterkellerung des Hauses', 'Unterkellerung des Hauses', basementOptions)}
      {selectRow(
        'basementAccess',
        'Der Zugang zum Keller erfolgt',
        'Zugang zum Keller',
        basementAccessOptions,
        !['vollständig', 'teilweise'].includes(values.basement)
      )}
      {selectRow(
        'basementAccessConnection',
        'Die Verbindung zum Wohnbereich ist',
        'Verbindung zum Wohnbereich',
        basementAccessConnectionOptions,
        !['vollständig', 'teilweise'].includes(values.basement) || values.basementAccess !== 'vom Wohnbereich'
      )}
      {selectRow('miscellaneous', 'Sonstiges', 'Sonstiges', miscellaneousOptions)}
      {selectRow('windowTightness', 'Dichtheit der Fenster', 'Dichtheit der Fenster', windowTightnessOptions)}
      {selectRow('ventilationSystem', 'Lüftungsanlage', 'Lüftungsanlage', ventilationSystemOptions)}
      {selectRow(
        'structuralMeasures',
        'Bauliche Maßnahmen',
        'Wurden im Gebäude/in der Wohnung bauliche Maßnahmen zur energetischen Ertüchtigung durchgeführt?',
        yesNoUnknownOptions
      )}
      {selectRow(
        'structuralTechnicalMeasures',
        'Radonsanierung',
        'Wurden im Gebäude/in der Wohnung bereits baulich-technische Maßnahmen zur Radonsanierung durchgeführt?',
        yesNoUnknownOptions
      )}
      {selectRow(
        'radonMeasurements',
        'Radonmessungen',
        'Wurde im Gebäude/in der Wohnung schon einmal Radon gemessen?',
        yesNoUnknownOptions
      )}
    </>
  );
};

export default GebaeudeinformationenForm;
