import { Button, Card, Form } from 'react-bootstrap';
import CardContent from '../../../../../components/Content/components/CardContent';
import * as React from 'react';
import { FormEventHandler, useState } from 'react';
import Colors from '../../../../../util/colors';

const Template = (): JSX.Element => {
  const [email, setEmail] = useState('');

  const [requestSent, setRequestSent] = useState(false);
  const [successful, setSuccessful] = useState(false);
  const [error, setError] = useState(false);

  const onReset: FormEventHandler<HTMLFormElement> = async e => {
    e.preventDefault();
    if (requestSent || !email) {
      return;
    }
    setRequestSent(true);

    const processError = () => {
      setRequestSent(false);
      setSuccessful(false);
      setError(true);
    };

    try {
      const response = await fetch(`/api/resetPassword.php?email=${email}`);
      if (response.status !== 200) {
        processError();
      }

      setRequestSent(false);
      setSuccessful(true);
      setError(false);
    } catch (e) {
      processError();
    }
  };

  const body = successful ? (
    <div>
      Um ein neues Passwort zu vergeben, erhalten Sie von uns eine Mail mit einem Link. Schauen Sie bitte in Ihr
      Mail-Postfach. Durch Klicken auf den Link werden Sie auf eine Seite geleitet, auf der Sie ein neues Passwort
      vergeben können.
    </div>
  ) : (
    <Form onSubmit={onReset}>
      <h5>Geben Sie hier bitte Ihre Mail-Adresse ein und senden Sie diese ab.</h5>
      <br />
      <Form.Group className={'mb-4'} controlId={'resetEmail'}>
        <Form.Label>Mail-Adresse</Form.Label>
        <Form.Control
          type={'text'}
          placeholder={'Mail-Adresse'}
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
      </Form.Group>
      {error && (
        <h5 style={{ color: Colors.red }} className={'mb-3'}>
          Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.
        </h5>
      )}
      <Button variant={'primary'} type={'submit'} disabled={requestSent || !email}>
        Passwort zurücksetzen
      </Button>
    </Form>
  );

  return (
    <>
      <Card.Header>
        <h4>Passwort vergessen</h4>
      </Card.Header>
      <Card.Body>{body}</Card.Body>
    </>
  );
};

const passwortVergessen = {
  name: 'Passwort vergessen',
  defaultRoute: '/Radon_Stadt/Passwort_vergessen',
  type: CardContent,
  template: Template,
};

export default passwortVergessen;
