import { Button, Col, Form, Row } from 'react-bootstrap';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Colors from '../../../../../util/colors';
import * as React from 'react';
import { setJwt } from '../../../../../util/jwtProvider';
import passwortVergessen from '../../Gebaeudeinformation/pages/PasswortVergessen';

type Props = {
  onRefresh: () => void;
  loginDisabled: boolean;
};

const LoginForm = ({ onRefresh, loginDisabled }: Props): JSX.Element => {
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null as boolean | null);

  const login = async (email: string, password: string) => {
    setDisabled(true);
    try {
      const response = await fetch('/api/session.php', {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: {
          'content-Type': 'application/json',
        },
      });
      if (response.status === 201) {
        const jwt = await response.json();
        setJwt(jwt[0]);
        setError(false);
        onRefresh();
      } else {
        setError(true);
        setDisabled(false);
      }
    } catch (e) {
      setError(true);
      setDisabled(false);
    }
  };

  return (
    <Form>
      <Row>
        <Form.Group className={'mb-3'} controlId={'loginEmail'} as={Col} md={'8'}>
          <Form.Label>Mail-Adresse</Form.Label>
          <Form.Control
            type={'email'}
            placeholder={'Mail-Adresse'}
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className={'mb-4'} controlId={'loginPassword'} as={Col} md={'8'}>
          <Form.Label>Passwort</Form.Label>
          <Form.Control
            type={showPassword ? 'text' : 'password'}
            placeholder={'Passwort'}
            value={password}
            onChange={e => setPassword(e.target.value)}
          />
        </Form.Group>
        <Form.Group className={'mb-3'} as={Col} md={'4'}>
          <Form.Label style={{ visibility: 'hidden' }} className={'mb-3'}>
            Passwort
          </Form.Label>
          <Form.Check
            type="checkbox"
            label="Passwort anzeigen"
            onChange={() => setShowPassword(!showPassword)}
            checked={showPassword}
          />
        </Form.Group>
      </Row>
      {error && (
        <p>
          <span style={{ color: Colors.red }}>Login fehlgeschlagen!</span>
        </p>
      )}
      <Button variant={'primary'} onClick={() => login(email, password)} disabled={disabled || loginDisabled}>
        Login
      </Button>{' '}
      <Button variant={'outline-primary'} onClick={() => history.push(passwortVergessen.defaultRoute)}>
        Passwort vergessen
      </Button>
    </Form>
  );
};

export default LoginForm;
