import home from '../pages/home';
import { Menu } from './types';
import unserePhilosophie from '../pages/Unternehmen/UnserePhilosophie';
import ansprechpartnerUndQualifikationen from '../pages/Unternehmen/AnsprechpartnerUndQualifikationen';
import kooperationen from '../pages/Unternehmen/Kooperationen';
import publikationen from '../pages/Unternehmen/Publikationen';
import leistungsuebersicht from '../pages/Leistungsuebersicht/Leistungsuebersicht';
import radon2020 from '../pages/Radon2020/Beschreibung';
import referenzenUndAuftraggeber from '../pages/Unternehmen/ReferenzenUndAuftraggeber';
import radonInDerBodenluft from '../pages/Leistungsuebersicht/RadonInDerBodenluft';
import radonInOeffentlichenGebaeuden from '../pages/Leistungsuebersicht/RadonInOeffentlichenGebaeuden';
import radonAmArbeitsplatz from '../pages/Leistungsuebersicht/RadonAmArbeitsplatz';
import radionuklideInDerUmwelt from '../pages/Leistungsuebersicht/RadionuklideInDerUmwelt';
import privathaushalte from '../pages/Leistungsuebersicht/Privathaushalte';
import beschreibung from '../pages/RadonStadt/Beschreibung';
import anmeldung from '../pages/RadonStadt/Anmeldung';
import aufstellungsanleitungRadonstadt from '../pages/RadonStadt/Austellungsanleitung';
import ablauf from '../pages/RadonStadt/Ablauf';
import downloadsRadonstadt from '../pages/RadonStadt/Downloads';
import datenverarbeitungRadonstadt from '../pages/RadonStadt/Datenverarbeitung';
import mainRadonstadt from '../pages/RadonStadt/RadonStadt';
import gebaeudeinformationen from '../pages/RadonStadt/Gebaeudeinformationen';

const menu: Menu = [
  home,
  {
    name: 'Unternehmen',
    entries: [
      unserePhilosophie,
      ansprechpartnerUndQualifikationen,
      kooperationen,
      publikationen,
      referenzenUndAuftraggeber,
    ],
  },
  {
    name: 'Leistungsübersicht',
    entries: [
      leistungsuebersicht,
      radonInDerBodenluft,
      radonInOeffentlichenGebaeuden,
      radonAmArbeitsplatz,
      radionuklideInDerUmwelt,
      privathaushalte,
    ],
  },
  {
    name: 'Radon Stadt',
    rootEntry: mainRadonstadt,
    entries: [
      beschreibung,
      ablauf,
      anmeldung,
      aufstellungsanleitungRadonstadt,
      gebaeudeinformationen,
      downloadsRadonstadt,
      datenverarbeitungRadonstadt,
    ],
  },
  radon2020,
];
export default menu;
