import { boolean, date, InferType, mixed, number, object, string } from 'yup';

export const yesNoUnknownOptions = ['ja', 'nein', 'unbekannt'];
export const yesNoOptions = ['ja', 'nein'];

export const typeOfBuildingOptions = [
  'Ein- oder Zweifamilienhaus',
  'Reihen- oder Doppelhaus',
  'Mehrfamilienhaus',
  'Wohnblock',
  'Wohnhochhaus',
  'Terrassenhaus',
  'Bauernhaus',
  'Büro- oder sonstiges Gebäude',
  'Fabrik, Lagergebäude',
];

export const buildingYearRangeOptions = [
  'vor 1900',
  '1900 bis 1945',
  '1946 bis 1960',
  '1961 bis 1970',
  '1971 bis 1980',
  '1981 bis 1985',
  '1986 bis 1995',
  '1996 bis 2000',
  '2001 bis 2005',
  '2006 bis 2010',
  '2011 bis 2015',
  'ab 2016',
];

export const buildingTypeOptions = ['Massivhaus', 'Fachwerkhaus', 'Fertighaus', 'Holzhaus', 'andere'];

export const residentialUnitsOptions = [
  '1 Wohneinheit',
  '2 Wohneinheiten',
  '3 - 6 Wohneinheiten',
  '7 - 12 Wohneinheiten',
  '13 und mehr Wohneinheiten',
];

export const personsOptions = [
  '1 Person',
  '2 Personen',
  '3 Personen',
  '4 Personen',
  '5 Personen und mehr',
  'keine Angabe',
];

export const basementOptions = ['vollständig', 'teilweise', 'keine'];

export const basementAccessOptions = ['vom Wohnbereich', 'über (Haus)Flur', 'nur von außen', 'von innen und außen'];

export const basementAccessConnectionOptions = ['offen', 'durch Tür verschlossen'];

export const miscellaneousOptions = ['Tiefgarage vorhanden', 'keine Tiefgarage vorhanden'];

export const windowTightnessOptions = ['dicht/sehr dicht', 'weniger dicht/nicht dicht'];

export const ventilationSystemOptions = [
  'Raumlufttechnische Anlage (mehrere Räume)',
  'Abluftanlage (nur Küche und/oder Bad)',
  'keine (freie Lüftung, z.B. Fenster- oder Schachtlüftung)',
];

export const gebaeudedatenSchema = object({
  typeOfBuilding: string().default('').oneOf(typeOfBuildingOptions),
  buildingYear: mixed().when('buildingYearUnknown', {
    is: false,
    then: number().integer().min(1000).required(),
    otherwise: string(),
  }),
  buildingYearUnknown: boolean().default(false).required(),
  buildingYearRange: string()
    .default('')
    .when('buildingYearUnknown', {
      is: true,
      then: string().required().oneOf(buildingYearRangeOptions),
    }),
  buildingType: string().default(''),
  insulation: string().default(''),
  insulationWindows: string().default(''),
  residentialUnits: string().default('').required().oneOf(residentialUnitsOptions),
  persons: string().default('').required().oneOf(personsOptions),
  basement: string().default(''),
  basementAccess: string().default(''),
  basementAccessConnection: string().default(''),
  miscellaneous: string().default(''),
  windowTightness: string().default(''),
  ventilationSystem: string().default(''),
  structuralMeasures: string().default(''),
  structuralTechnicalMeasures: string().default(''),
  radonMeasurements: string().default(''),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GebaeudedatenType extends InferType<typeof gebaeudedatenSchema> {}

export const storyOptions = [
  'Keller',
  'Souterrain/Tiefparterre',
  'Erdgeschoss',
  '1. Etage',
  '2. Etage',
  '3. Etage oder höher',
];

export const roomOptions = [
  'Wohnzimmer',
  'Esszimmer',
  'Wohnküche',
  'Schlafzimmer',
  'Kinderzimmer',
  'Arbeitszimmer',
  'Hobbyraum',
  'Vorrats-/Abstellraum',
  'Haustechnik/-anschluss',
  'Waschküche',
  'Sonstiges',
];

export const walkThroughTimeOptions = ['< 2 h/Woche', '2 bis 14 h/Woche', '15 bis 30 h/Woche', '> 30 h/Woche'];

export const exposimeterSchema = object({
  exposimeterNr: string().default(''),
  measurementStart: date().required(),
  measurementEnd: mixed()
    .test(
      'is-null-empty-date-or-valid-date-string',
      'The field must be null, an empty string, a valid date, or a string in the format "YYYY-MM-DD"',
      function (value: unknown) {
        return (
          !value ||
          (value instanceof Date && !isNaN(value.getTime())) ||
          (typeof value === 'string' && /^\d{4}-\d{2}-\d{2}$/.test(value) && !isNaN(new Date(value).getTime()))
        );
      }
    )
    .test('is-after-measurementStart', 'measurementEnd must be after measurementStart', function (value: unknown) {
      const { measurementStart } = this.parent;
      const endDate = typeof value === 'string' ? new Date(value) : value instanceof Date ? value : null;
      const startDate = measurementStart instanceof Date ? measurementStart : null;

      if (!endDate || !startDate) {
        return true;
      }

      return endDate > startDate;
    }),
  story: string().default('').required().oneOf(storyOptions),
  room: string().default('').required().oneOf(roomOptions),
  connectionToFloor: string().default('').required().oneOf(yesNoOptions),
  connectionToEarth: string().default('').required().oneOf(yesNoUnknownOptions),
  walkThroughTime: string().default('').required().oneOf(walkThroughTimeOptions),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ExposimeterType extends InferType<typeof exposimeterSchema> {}

export const gebaeudeinformationenSchema = object({
  building: gebaeudedatenSchema.required(),
  exposimeter1: exposimeterSchema.required(),
  exposimeter2: exposimeterSchema.required(),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface GebaeudeinformationenType extends InferType<typeof gebaeudeinformationenSchema> {}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const initialValues: LoginType = gebaeudeinformationenSchema.default();
