import { Button, Card, Col, Form, InputGroup, Row } from 'react-bootstrap';
import CardContent from '../../../../../components/Content/components/CardContent';
import * as React from 'react';
import { useState } from 'react';
import { InferType, object, ref, string } from 'yup';
import { Formik, FormikValues } from 'formik';
import { useParams } from 'react-router-dom';
import Colors from '../../../../../util/colors';

const resetPasswordSchema = object({
  password: string()
    .required()
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/)
    .default(''),
  passwordConfirm: string()
    .oneOf([ref('password')])
    .default(''),
});

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ResetPasswordSchema extends InferType<typeof resetPasswordSchema> {}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const initalValues: ResetPasswordSchema = resetPasswordSchema.default();

const Template = (): JSX.Element => {
  const { id, key } = useParams<{ id: string; key: string }>();

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);

  const submit = async (values: FormikValues) => {
    if (!enabled) {
      return;
    }
    setEnabled(false);
    const body = {
      password: values.password,
    };

    try {
      const response = await fetch(`/api/resetPassword.php?id=${id}&key=${key}`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'content-Type': 'application/json',
        },
      });
      if (response.status !== 201) {
        setError(true);
      } else {
        setSuccess(true);
      }
    } catch (e) {
      setError(true);
    }
    setEnabled(true);
  };

  if (success) {
    return (
      <>
        <Card.Header>
          <h4>Passwort zurücksetzen</h4>
        </Card.Header>
        <Card.Body>
          <div>Ihr Passwort wurde erfolgreich zurückgesetzt.</div>
        </Card.Body>
      </>
    );
  }

  return (
    <>
      <Card.Header>
        <h4>Passwort zurücksetzen</h4>
      </Card.Header>
      <Card.Body>
        <Formik validationSchema={resetPasswordSchema} initialValues={initalValues} onSubmit={submit}>
          {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row>
                <Form.Group className={'mb-3'} controlId={'validationFormikPassword'} as={Col} md={'8'}>
                  <Form.Label>
                    Passwort* <small>(min. 8 Zeichen, muss Klein-/Großschreibung und eine Zahl enthalten)</small>
                  </Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type={showPassword ? 'text' : 'password'}
                      placeholder={'Passwort'}
                      name={'password'}
                      value={values.password}
                      onChange={handleChange}
                      isInvalid={!!touched.password && !!errors.password}
                      isValid={!!touched.password && !errors.password}
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3'} as={Col} md={'4'}>
                  <Form.Label style={{ visibility: 'hidden' }} className={'mb-3'}>
                    Passwort*
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Passwort anzeigen"
                    onChange={() => setShowPassword(!showPassword)}
                    checked={showPassword}
                  />
                </Form.Group>
              </Row>
              <Row>
                <Form.Group className={'mb-4'} controlId={'validationFormikPasswordConfirm'} as={Col} md={'8'}>
                  <Form.Label>Passwort wiederholen*</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type={showPasswordRepeat ? 'text' : 'password'}
                      placeholder={'Passwort wiederholen'}
                      name={'passwordConfirm'}
                      value={values.passwordConfirm}
                      onChange={handleChange}
                      isInvalid={!!touched.passwordConfirm && !!errors.passwordConfirm}
                      isValid={
                        !!touched.passwordConfirm &&
                        !errors.passwordConfirm &&
                        (values.passwordConfirm?.length ?? 0) > 0
                      }
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group className={'mb-3'} as={Col} md={'4'}>
                  <Form.Label style={{ visibility: 'hidden' }} className={'mb-3'}>
                    Passwort wiederholen*
                  </Form.Label>
                  <Form.Check
                    type="checkbox"
                    label="Passwort anzeigen"
                    onChange={() => setShowPasswordRepeat(!showPasswordRepeat)}
                    checked={showPasswordRepeat}
                  />
                </Form.Group>
              </Row>
              {error && (
                <h5 style={{ color: Colors.red }} className={'mb-3'}>
                  Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.
                </h5>
              )}
              <Button variant={'primary'} type={'submit'} disabled={!enabled}>
                Neues Passwort setzen
              </Button>
            </Form>
          )}
        </Formik>
      </Card.Body>
    </>
  );
};

const passwortZuruecksetzen = {
  name: 'Passwort Zurücksetzen',
  defaultRoute: '/Radon_Stadt/Passwort_zurücksetzen/:id/:key',
  type: CardContent,
  template: Template,
};

export default passwortZuruecksetzen;
